import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { PlotGeojson } from '../models';
import { map, switchMap } from 'rxjs/operators';
import { PlotDialogComponent } from '@farm/map/plot-dialog/plot-dialog.component';
import { FieldService, Plot } from '@shared/core';

@Injectable({
  providedIn: 'root'
})
export class PlotDialogService {
  constructor(private dialog: MatDialog, private fieldService: FieldService) {}

  public open(plot: Plot): Observable<PlotGeojson> {
    return this.fieldService.get(plot.field_id).pipe(
      switchMap(field => {
        return this.dialog
          .open(PlotDialogComponent, {
            disableClose: true,
            width: '500px',
            data: { plot, field }
          })
          .afterClosed();
      }),
      map(response => this.transform(response))
    );
  }

  transform(plot: Plot): PlotGeojson {
    if (!plot) {
      return null;
    }

    return {
      geometry: { type: 'Polygon', coordinates: [plot.boundary.bounds] },
      type: 'Feature',
      properties: plot
    };
  }
}
