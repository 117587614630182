import { Component, OnInit, Input, ContentChild } from '@angular/core';

import { ViewModeDirective } from '../directives/view-mode.directive';
import { EditModeDirective } from '../directives/edit-mode.directive';

@Component({
  selector: 'app-editable',
  template: require('./editable.component.html'),
  styles: [require('./editable.component.scss')]
})
export class EditableComponent implements OnInit {
  @Input() isEditing: boolean;
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;

  constructor() { }

  ngOnInit() {
  }


  get currentView() {
    return this.isEditing ? this.editModeTpl.tpl : this.viewModeTpl.tpl;
  }

}
