import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { HarvestDetailsDialogComponent } from '@potato/harvest-details-dialog/harvest-details-dialog.component';
import { PlotService } from '@shared/core/services/plot.service';
import { Plot } from '@shared/core/models/plot.model';

@Injectable({
  providedIn: 'root'
})
export class HarvestDialogService {
  constructor(private dialog: MatDialog, private plotService: PlotService) {}

  public open(
    plotId: number,
    // tslint:disable-next-line: variable-name
    start_date?: Date,
    yieldTha?: number
  ): Observable<Plot> {
    return this.dialog
      .open(HarvestDetailsDialogComponent, {
        data: {
          start_date,
          yield: yieldTha
        },
        width: '500px'
      })
      .afterClosed()
      .pipe(
        filter(result => result),
        switchMap(result => {
          // update via the Plot API
          return this.plotService.get(plotId).pipe(
            switchMap(plot => {
              delete plot.harvest;
              plot.actual_harvest_date = result.start_date;
              if (result.yield) {
                plot.actual_yield = result.yield;
              }
              return this.plotService.update(plot);
            })
          );
        })
      );
  }
}
