import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {Subscription, SubscriptionLike} from 'rxjs';

import { Banner } from './shared/models/banner.model';
import {BannerService} from './shared/services/banner.service';

@Component({
    selector: 'app-generic-banner',
    template: require('./generic-banner.component.html'),
    styles: [require('./generic-banner.component.scss')]
})
export class GenericBannerComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();
    visibleBanner: Banner;
    lastVisitedUrl: string;

    constructor(private location: Location, private bannerService: BannerService) { }

    ngOnInit() {
        this.subscriptions.add(this.bannerService.bannersReady$.subscribe((bannerReady) => {
            this.visibleBanner = this.bannerService.getBanner(
                this.location.path(true)
            );
        }));
        this.subscriptions.add(this.location.subscribe(location => {
            const url = location.url;
            // Preventing multiple function calls for same URL
            if (this.lastVisitedUrl !== url) {
                this.lastVisitedUrl = url;
                this.visibleBanner = this.bannerService.getBanner(location.url);
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
