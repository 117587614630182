import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {OperationService} from '@shared/core/services/operation.service';
import {ObservationReportService} from '@shared/core/services/observation-report.service';
import {CheckDeletePlotDialogComponent} from '@farm/check-delete-plot-dialog/check-delete-plot-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DeletePlotService {

  constructor(public dialog: MatDialog,
              private operationService: OperationService,
              private observationService: ObservationReportService) { }

  /**
   * Delete a plot but only if it contains no observations of operations
   * @param id: plot id
   */
  public checkDeletePlot(id: number) : Observable<boolean> {
    return forkJoin( this.operationService.canDeletePlot(id),
                     this.observationService.getCountForPlot(id) )
        .pipe(
          switchMap( (results) => {
              return this.dialog.open(CheckDeletePlotDialogComponent, {
                  data: {
                  id: id,
                  canDelete: results[0] === true && results[1] === 0
                }
              }).afterClosed();
          })
        );
  }
}
