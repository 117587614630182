import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-estimated-actual-cell',
  template: require('./estimated-actual-cell.component.html'),
  styles: [require('./estimated-actual-cell.component.scss')]
})
export class EstimatedActualCellComponent implements OnInit {
  @Input() estimated: Date;
  @Input() actual: Date;

  constructor() { }

  ngOnInit() {
  }

}
