import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VarietyService } from '@shared/core/services/variety.service';
import { AddVarietyDialogComponent } from './add-variety-dialog/add-variety-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AddVarietyService {
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private varietyService: VarietyService
  ) {}

  public open() {
    return this.dialog
      .open(AddVarietyDialogComponent, {
        width: '500px'
      })
      .afterClosed()
      .subscribe(variety => {
        if (variety) {
          this.varietyService.save(variety).subscribe(
            _ => {
              this.snackBar.open('Variety saved');
            },
            () => {
              this.snackBar.open(
                'Failed to save variety - please contact support',
                'OK',
                {
                  duration: 5000
                }
              );
            }
          );
        }
      });
  }
}
