import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ProductSummary } from '@shared/core/models/product-summary';
import { ProductsService } from '@shared/core/services/products.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerFormats } from '@shared/core/constants/date-formats';

@Component({
  selector: 'app-burndown-date-dialog',
  template: require('./burndown-date-dialog.component.html'),
  styles: [require('./burndown-date-dialog.component.scss')],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DatePickerFormats }]
})
export class BurndownDateDialogComponent implements OnInit {
  form: FormGroup;
  filteredProducts$: Observable<ProductSummary[]>;

  constructor(
    private fb: FormBuilder,
    private productService: ProductsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BurndownDateDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      start_date: [this.data.start_date, Validators.required],
      product: [null]
    });

    if (this.data.burndown_product_id) {
      // load the initial product
      this.productService
        .getPesticideSummary(this.data.burndown_product_id)
        .subscribe(product => {
          this.form.get('product').setValue(product);
        });
    }

    this.filteredProducts$ = this.form.controls.product.valueChanges.pipe(
      debounceTime(100),
      switchMap(value =>
        this.productService
          .getPesticideSummaries(value)
          .pipe(map(resp => resp.results))
      )
    );
  }

  displayProduct(product?: ProductSummary) {
    return product && product.name ? product.name : '';
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
