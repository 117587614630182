import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesSummaryComponent } from './articles-summary/articles-summary.component';
import { MaterialModule } from '@shared/material';
import { ArticleService } from './services/article.service';

@NgModule({
  declarations: [ArticlesSummaryComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  providers: [
    ArticleService
  ]
})
export class ArticlesSummaryModule { }
