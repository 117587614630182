import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Field, FieldService, SoilType, SoilTypeService} from '@shared/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {iif, Observable, ReplaySubject, Subject} from 'rxjs';
import {debounceTime, filter, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {NameUniquenessValidator} from '@farm/map/common/name-uniqueness-validator';
import {FarmService} from '@shared/core/services/farm.service';
import {Farm} from '@shared/core/models/farm.model';

@Component({
  selector: 'app-field-dialog',
  template: require('./field-dialog.component.html'),
  styles: [require('./field-dialog.component.scss')]
})
export class FieldDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isNew: boolean;
  public farm$: ReplaySubject<Farm[]> = new ReplaySubject<Farm[]>(1);
  soilTypes$: Observable<Array<SoilType>>;
  public farmFilterControl: FormControl = new FormControl();
  public isSearchingFarm = false;
  protected unsubscribe$ = new Subject<void>();
  AREA_MIN = 0.0001;
  AREA_MAX = 999.9999;

  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: Field,
    private dialogRef: MatDialogRef<FieldDialogComponent>,
    private fieldService: FieldService,
    private farmService: FarmService,
    private snackBar: MatSnackBar,
    private soilTypeService: SoilTypeService
) { }

  ngOnInit() {
    const field=this.data;
    this.soilTypes$ = this.soilTypeService.query();
    this.isNew = !this.data.id;
    this.form = this.fb.group({
      id: [this.data.id],
      name: [
        field.name,
        [Validators.required, Validators.maxLength(100)],
        NameUniquenessValidator(this.fieldService, field.id)
      ],
      farm_id: [field.farm_id, Validators.required],
      government_id: [ this.data.government_id],
      soil_type_id: [
        field.soil_type_id || field.soil_type_id,
        [Validators.required]
      ],
      area: [
        field.boundary.area,
        [
          Validators.required,
          Validators.min(this.AREA_MIN),
          Validators.max(this.AREA_MAX)
        ]
      ],
      notes: [field.notes]
    });

    // this is largely identical to the logic in the farm-dialog, and would ideally be refactored
    // into a common component
    // we should fix in Phoenix
    this.farmFilterControl.valueChanges
      .pipe(
        filter(search => !!search && !!search.trim()),
        startWith(''),
        tap(() => (this.isSearchingFarm = true)),
        takeUntil(this.unsubscribe$),
        debounceTime(200),
        switchMap((search: string) => this.farmService.query(search, true)),
        map(page => page.results)
      )
      .subscribe(
        farms => {
          this.isSearchingFarm = false;
          this.farm$.next(farms);
        },
        error => {
          this.isSearchingFarm = false;
          this.snackBar.open(
            'Something went wrong while retrieving the farms! Please contact your support team.',
            'CLOSE'
          );
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    const field = this.form.getRawValue();
    field.boundary = {
      bounds: this.data.boundary.bounds,
      area: field.area
    };
    delete field['area'];
    iif(
      () => this.isNew,
      this.fieldService.create(field),
      this.fieldService.update(field)
    ).subscribe(
      response => {
        this.dialogRef.close(response);
      },
      err => {
        this.snackBar.open(
          'Something went wrong! Please contact support team.',
          'CLOSE'
        );
      }
    );
  }

}
