import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PlotService} from '@shared/core/services/plot.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {DeleteFieldDialogComponent} from '@farm/delete-field-dialog/delete-field-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DeleteFieldService {

  constructor(public dialog: MatDialog,
              private plotService: PlotService) { }

  public checkDeleteField(id: number) : Observable<boolean> {
        return this.plotService.getForField(id).pipe(
        switchMap((fieldPage) => {
          return this.dialog.open(DeleteFieldDialogComponent, {
            data: {
              id: id,
              canDelete: fieldPage.total === 0
            }
          }).afterClosed();
        }));
  }
}
