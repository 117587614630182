import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UpgradeModule} from '@angular/upgrade/static';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {CoreModule} from '@shared/core';
import {MaterialModule} from '@shared/material';

import {AppComponent} from './app.component';
import {FarmModule} from './farm/farm.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {GenericBannerModule} from './generic-banner/generic-banner.module';
import {SettingsModule} from './settings/settings.module';
import {HeaderInterceptor} from '@shared/utils/interceptor/header.interceptor';
import {SoilReportModule} from './soil-report/soil-report.module';
import {UploadModule} from './upload/upload.module';
import {PotatoModule} from '@potato/potato.module';
import { WeatherModule } from '@weather/weather.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        UpgradeModule,
        FarmModule,
        SoilReportModule,
        DashboardModule,
        PotatoModule,
        SettingsModule,
        UploadModule,
        WeatherModule,
        GenericBannerModule,

        CoreModule.forRoot(),
        MaterialModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        }
    ],
    entryComponents: [AppComponent],
    bootstrap: []
})
export class AppModule {
    private ngApp: string;

    constructor(private upgrade: UpgradeModule) {
    }

    ngDoBootstrap() {
        const input = document.getElementById('ngApp') as HTMLInputElement;
        this.ngApp = input.value;
        this.upgrade.bootstrap(document.body, [this.ngApp], {strictDi: false});
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
