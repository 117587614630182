import { Injectable, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import { Banner } from '../models/banner.model';
import {
  CheckClientAndTenantService,
  PlatformSettings,
  PlatformSettingsService
} from '@shared/core';

@Injectable()
export class BannerService {
  bannersReady$ = new Subject();

  constructor(private checkClientTenantService: CheckClientAndTenantService,
              private platformSettingService: PlatformSettingsService) {
    this.platformSettingService.getAvailableSettings()
        .subscribe((val: PlatformSettings) => {
          this.clientId = val.CLIENT_ID;
          if (this.isClientUK()) {
            this.banners = this.banners.concat(this.ukBanners);
          }
          this.bannersReady$.next();
        });
  }

  private static readonly MY_FARM_MOVE_MESSAGE =
    'The new map has now been released. Click the map icon in MyFarm to go to it.';

  private static readonly YIELD_MODEL_REMOVAL_MESSAGE = `We are discontinuing the yield model 
    service, which will not be available for 2021. Yield dig and background data collection 
    will remain possible here, if you have any questions please get in touch with us.`;

  private static readonly AGROMET_REMOVAL_MESSAGE =
      'Weather station integration will not be supported beyond the end of April. ' +
      'To view weather data for plots, please go to the Map and click on the weather widget for a crop.';

  clientId = '';

  banners: Array<Banner> = [
    {
      url: '/dashboard/farm#/myfarm/map',
      template: BannerService.MY_FARM_MOVE_MESSAGE
    },
    {
      url: '/dashboard/farm#/myfarm/plot/inventory',
      template: BannerService.MY_FARM_MOVE_MESSAGE
    },
    {
      url: '/dashboard/farm#/profile/',
      template: BannerService.MY_FARM_MOVE_MESSAGE
    },
    {
      url: '/dashboard/farm#/soil-results/',
      template: BannerService.MY_FARM_MOVE_MESSAGE
    },
    {
      url: '/dashboard/farm#/sharing/',
      template: BannerService.MY_FARM_MOVE_MESSAGE
    },
    {
      url: '/dashboard/farm#/crop-variety/',
      template: BannerService.MY_FARM_MOVE_MESSAGE
    },
    {
      url: '/dashboard/crops/potato#/',
      template: BannerService.YIELD_MODEL_REMOVAL_MESSAGE
    }
  ];

  ukBanners: Array<Banner> = [
    {
      url: '/dashboard/weather#/',
      template: BannerService.AGROMET_REMOVAL_MESSAGE
    },
    {
      url: '/dashboard/weather#/sensorlist/',
      template: BannerService.AGROMET_REMOVAL_MESSAGE
    },
    {
      url: '/dashboard/weather#/override/',
      template: BannerService.AGROMET_REMOVAL_MESSAGE
    },
    {
      url: '/dashboard/weather#/regionalclimatesummary/',
      template: BannerService.AGROMET_REMOVAL_MESSAGE
    },
  ];

  isClientUK(): boolean {
    return this.checkClientTenantService.isClientUK(this.clientId);
  }

  getBanner(url: string): Banner {
    return this.banners.find(banner => banner.url === url);
  }
}
