import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FarmService} from '@shared/core/services/farm.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-delete-dialog',
  template: require('./delete-farm-dialog.component.html'),
  styles: [require('./delete-farm-dialog.component.scss')]
})
export class DeleteFarmDialogComponent implements OnInit {

  public canDelete: boolean;

  constructor(public dialogRef: MatDialogRef<DeleteFarmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private farmService: FarmService) { }

  ngOnInit() {
    this.canDelete = this.data.canDelete;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  ok() {
    this.farmService.delete(this.data.id).subscribe( () => {
      this.dialogRef.close(true);
    }, ()=> {
      // fail
      this.snackBar.open("Failed to delete farm", "OK", { duration: 5000 });
      this.dialogRef.close(false);
    })
  }

}
