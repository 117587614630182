import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { saveAs } from 'file-saver';
import { FieldInventoryService } from '../services/field-inventory.service';
import { FarmFilter } from '@shared/core';
import {
  Crop,
  FieldInventory,
  FieldInventoryFilter
} from '@farm/field-inventory/models';
import { GenusVarietyService } from '@shared/core/services/genus-variety.service';
import { FieldStats } from '@shared/charts/field-stats/models/field-stats.model';

@Component({
  selector: 'app-field-inventory',
  template: require('./field-inventory.component.html'),
  styles: [require('./field-inventory.component.scss')]
})
export class FieldInventoryComponent implements OnInit, OnChanges {
  @Input() selectedPlotId: number;
  @Input() farmFilter: FarmFilter;
  @Output() itemClick = new EventEmitter<FieldInventory>();

  crops: Crop[];
  isEditMode: boolean;
  fieldStat: FieldStats;
  fieldInventoryFilter: FieldInventoryFilter;

  constructor(
    private fieldInventoryService: FieldInventoryService,
    private genusVarietyService: GenusVarietyService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.farmFilter && this.farmFilter) {
      this.loadCrops();
    }
  }

  loadCrops(): void {
    this.genusVarietyService.getCrops(this.farmFilter).subscribe(crops => {
      this.crops = crops;
    });
  }
  onFilterChange(fieldInventoryFilter: FieldInventoryFilter): void {
    this.fieldInventoryFilter = fieldInventoryFilter;
    this.reloadItems();
  }

  private reloadItems() {
    if (this.farmFilter && this.fieldInventoryFilter) {
      this.fieldInventoryService
        .getStats(this.farmFilter, this.fieldInventoryFilter)
        .subscribe(fieldStat => (this.fieldStat = fieldStat));

      if (this.selectedPlotId) {
        this.fieldInventoryService
          .getPlot(this.selectedPlotId)
          .subscribe(selectedItem => {
            if (selectedItem !== null) {
              this.selectedPlotId = null;
              this.onItemClicked(selectedItem);
            }
          });
      }
    }
  }

  exportToCsv(): void {
    this.fieldInventoryService
      .getCsv(this.farmFilter, this.fieldInventoryFilter)
      .subscribe((file: BlobPart) => {
        const blob = new Blob([file], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'crop-inventory.csv');
      });
  }

  onEditModeChange(isEditMode: boolean) {
    this.isEditMode = isEditMode;
  }
  onItemClicked(item: FieldInventory): void {
    this.itemClick.emit(item);
  }
  onItemsUpdated(items: FieldInventory[]): void {
    this.reloadItems();
  }
}
