import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {FieldStatsModule} from '@shared/charts/field-stats';
import {MaterialModule} from '@shared/material';
import {CoreModule} from '@shared/core';

import {VarietyService} from '@shared/core/services/variety.service';
import {FieldInventoryFilterComponent} from './field-inventory-filter/field-inventory-filter.component';
import {FileExporterModule} from '@shared/file-exporter';
import {EstimatedActualCellModule} from './estimated-actual-cell';
import {EditableComponent} from './editable/editable.component';
import {ViewModeDirective} from './directives/view-mode.directive';
import {EditModeDirective} from './directives/edit-mode.directive';
import {EditableDateComponent} from './editable-date/editable-date.component';
import {EmptyStateModule} from '@shared/components/empty-state/empty-state.module';
import {ProgressSpinnerModule} from '@shared/progress-spinner';
import {SetActualYieldComponent} from './set-actual-yield/set-actual-yield.component';
import {FieldInventoryComponent, FieldInventoryService, FieldInventoryTableComponent} from './field-inventory';
import {FieldInventoryDataSource} from './field-inventory/data-sources/field-inventory-data-source.service';

@NgModule({
  declarations: [
    FieldInventoryFilterComponent,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    EditableDateComponent,
    SetActualYieldComponent,
    FieldInventoryComponent,
    FieldInventoryTableComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    FieldStatsModule,
    FileExporterModule.forRoot(),
    EstimatedActualCellModule,
    EmptyStateModule,
    ProgressSpinnerModule
  ],
  providers: [
    VarietyService,
    FieldInventoryService,
    FieldInventoryDataSource
  ],
  entryComponents: [SetActualYieldComponent]
})
export class FieldInventoryModule {}
