import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-editable-date',
  template: require('./editable-date.component.html'),
  styles: [require('./editable-date.component.scss')]
})
export class EditableDateComponent implements OnInit {
  @Input() placeholder: string;
  @Input() color: string;
  @Input() item: FormControl;

  constructor() { }

  ngOnInit() {
  }
}
