import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms/src/model';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerFormats } from '@shared/core/constants/date-formats';

@Component({
  selector: 'app-harvest-details-dialog',
  template: require('./harvest-details-dialog.component.html'),
  styles: [require('./harvest-details-dialog.component.scss')],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DatePickerFormats }]
})
export class HarvestDetailsDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HarvestDetailsDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      start_date: [this.data.start_date, Validators.required],
      yield: [this.data.yield]
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
