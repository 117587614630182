import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material';
import {map} from 'rxjs/operators';
import {FarmDialogComponent} from '@farm/map/farm-dialog/farm-dialog.component';
import {Farm} from '@shared/core/models/farm.model';
import {FarmGeojson} from '@farm/map/common/models';

@Injectable({
  providedIn: 'root'
})
export class FarmDialogService {
  constructor(private dialog: MatDialog) {}

  public open(farm: Farm): Observable<FarmGeojson> {
    return this.dialog
      .open(FarmDialogComponent, {
        disableClose: true,
        width: '500px',
        data: farm
      })
      .afterClosed()
      .pipe(map(response => this.transform(response)));
  }

  transform(farm: Farm): FarmGeojson {
    if (!farm) {
      return null;
    }

    return {
      geometry: { type: 'Point', coordinates: farm.location },
      type: 'Feature',
      properties: farm
    };
  }
}
