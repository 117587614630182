import {Injectable} from '@angular/core';
import {FieldService} from '@shared/core/services/field.service';
import {MatDialog} from '@angular/material/dialog';
import {DeleteFarmDialogComponent} from '@farm/delete-farm-dialog/delete-farm-dialog.component';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeleteFarmService {

  constructor(public dialog: MatDialog,
              private fieldService: FieldService) { }

  /**
   * Delete a farm but only if it contains no fields
   * @param id: farm id
   */
  public checkDeleteFarm(id: number) : Observable<boolean> {
    return this.fieldService.getFieldsForFarm(id).pipe(
        switchMap((fieldPage) => {
          return this.dialog.open(DeleteFarmDialogComponent, {
            data: {
              id: id,
              canDelete: fieldPage.total === 0
            }
          }).afterClosed();
        }));
  }
}
