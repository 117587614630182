import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FieldInventory,
  FieldInventoryFilter,
  FieldInventoryRQ,
  Variety
} from '@farm/field-inventory/models';
import { FieldInventoryDataSource } from '../data-sources/field-inventory-data-source.service';
import { MatDialog, MatPaginator, MatSnackBar } from '@angular/material';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { TableRow } from '@farm/field-inventory/models/table-row.model';
import { forkJoin, merge, Observable, Subject } from 'rxjs';
import { VarietiesCacheService } from '../../services/varieties-cache.service';
import { FieldInventoryService } from '../services/field-inventory.service';
import { FarmFilter } from '@shared/core';
import _ from 'underscore';
import { environment } from '../../../../../environments/environment';
import { SetActualYieldComponent } from '@farm/field-inventory/set-actual-yield/set-actual-yield.component';
import moment from 'moment';
import {ifTrue} from "codelyzer/util/function";

interface FieldInventoryRow extends TableRow<FieldInventory> {
  filteredVarieties$: Observable<Variety[]>;
}

@Component({
  selector: 'app-field-inventory-table',
  template: require('./field-inventory-table.component.html'),
  styles: [require('./field-inventory-table.component.scss')]
})
export class FieldInventoryTableComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() farmFilter: FarmFilter;
  @Input() fieldInventoryFilter: FieldInventoryFilter;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() itemsUpdate = new EventEmitter<FieldInventory[]>();
  @Output() editModeChanged = new EventEmitter<boolean>();
  @Output() itemClick = new EventEmitter<FieldInventory>();

  pageSize = environment.pagination.size;
  pageSizeOptions = environment.pagination.options;

  displayedColumns: string[];
  editModeColumns: string[] = [
    'plot',
    'crop',
    'variety',
    'area',
    'estimatedYield',
    'totalProduction',
    'planting',
    'harvesting',
      'plotState'
  ];
  viewModeColumns: string[] = [
    'team',
    'field',
    'plot',
    'crop',
    'variety',
    'area',
    'estimatedYield',
    'totalProduction',
    'planting',
    'harvesting'
  ];
  private editMode: boolean;
  get isEditMode(): boolean {
    return this.editMode;
  }

  set isEditMode(mode: boolean) {
    if (this.editMode !== mode) {
      this.editMode = mode;
      this.editModeChanged.emit(mode);
    }

    this.displayedColumns = this.editMode
      ? this.editModeColumns
      : this.viewModeColumns;
  }

  form: FormGroup;

  protected unsubscribeObservables$: Subject<void> = new Subject();
  showEmptyState: boolean;
  lastFarmFilter: FarmFilter;
  lastInventoryFilter: FieldInventoryFilter;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public varietiesCacheService: VarietiesCacheService,
    private dialog: MatDialog,
    private fieldInventoryService: FieldInventoryService,
    private dataSource: FieldInventoryDataSource
  ) {
    this.form = this.fb.group({
      items: this.fb.array([])
    });
  }

  ngOnInit() {
    this.isEditMode = false;
    this.dataSource.results$
      .pipe(takeUntil(this.unsubscribeObservables$))
      .subscribe((tableRows: FieldInventoryRow[]) => {
        this.showEmptyState = tableRows.length === 0;
      });
  }

  ngOnDestroy() {
    this.unsubscribeObservables$.next();
    this.unsubscribeObservables$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // dedupe - only load the inventory is something has changed, or on first load
    if (this.farmFilter && this.fieldInventoryFilter) {
      if (
        !this.lastFarmFilter ||
        !_.isEqual(this.farmFilter, this.lastFarmFilter) ||
        !this.lastInventoryFilter ||
        !_.isEqual(this.fieldInventoryFilter, this.lastInventoryFilter)
      ) {
        this.lastFarmFilter = { ...this.farmFilter };
        this.lastInventoryFilter = { ...this.fieldInventoryFilter };
        this.paginator.pageIndex = 0;
        this.loadInventory();
      }
    }
  }
  ngAfterViewInit() {
    merge(this.paginator.page, this.itemsUpdate)
      .pipe(
        takeUntil(this.unsubscribeObservables$),
        tap(() => this.loadInventory())
      )
      .subscribe();
  }

  displayVariety(variety: Variety): string {
    return variety ? variety.name : '';
  }

  private loadInventory() {
    this.dataSource.loadItems(
      this.farmFilter,
      this.fieldInventoryFilter,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || this.pageSize
    );
  }

  enterEditMode(): void {
    this.isEditMode = true;
  }

  leaveEditMode(): void {
    this.isEditMode = false;
    this.dataSource.resetRows();
    this.form.controls.items = this.fb.array([]);
  }

  canSave(): boolean {
    return this.form.controls.items.valid && !this.form.controls.items.pristine;
  }

  save(): void {
    if (this.canSave()) {
      const formArray = this.form.controls.items as FormArray;
      const observables = formArray.controls
        .filter(formControl => !formControl.pristine)
        .map(formGroup => {
          const rq = this.generateFieldInventoryRQ(formGroup);
          return this.fieldInventoryService.update(rq);
        });
      forkJoin(observables).subscribe(
        (items: FieldInventory[]) => {
          this.leaveEditMode();
          this.itemsUpdate.emit(items);
        },
        err => {
          const message =
            err.error && err.error.message
              ? err.error.message
              : 'Something went wrong!';
          this.snackBar.open(message, 'CLOSE');
        }
      );
    }
  }

  private generateFieldInventoryRQ(
    formGroup: AbstractControl
  ): FieldInventoryRQ {
    const formValue = formGroup.value;

    const request: FieldInventoryRQ = {
      plot_id: formValue.id,
      open: formValue.isOpen,
      actual_yield: formValue.actualYield
    };

    function formatDate(dt: string): string {
      // we only want the date component: just treat as local and format as a date
      return moment(dt)
        .local()
        .format('YYYY-MM-DD');
    }

    request.planting_area = formValue.area;
    if (formValue.estimatedYield !== null) {
      request.planned_yield = formValue.estimatedYield;
    }
    if (formValue.variety) {
      request.variety_id = formValue.variety.id;
    }
    if (formValue.plannedPlantingDate) {
      request.planned_planting_date = formatDate(formValue.plannedPlantingDate);
    }
    if (formValue.actualPlantingDate) {
      request.actual_planting_date = formatDate(formValue.actualPlantingDate);
    }
    if (formValue.plannedHarvestDate) {
      request.planned_harvest_date = formatDate(formValue.plannedHarvestDate);
    }
    if (formValue.actualHarvestDate) {
      request.actual_harvest_date = formatDate(formValue.actualHarvestDate);
    }
    if (formValue.totalProduction) {
      request.actual_yield = formValue.totalProduction / request.planting_area;
    }
    return request;
  }

  getFormControls(
    tableRow: FieldInventoryRow
  ): { [key: string]: AbstractControl } {
    if (!tableRow.form) {
      tableRow.form = this.generateForm(tableRow);

      const items = this.form.controls.items as FormArray;
      items.push(tableRow.form);
    }

    return tableRow.form.controls;
  }

  showProductionInput(row: FieldInventoryRow): boolean {
    const control = this.getFormControls(row);
    return control.actualHarvestDate.value;
  }

  showEstimatedYieldInput(row: FieldInventoryRow): boolean {
    return (
      this.getFormControls(row).plannedHarvestDate.value ||
      row.item.estimated_yield
    );
  }

  private generateForm(row: FieldInventoryRow): FormGroup {
    const item = row.item;
    if (!item) {
      return null;
    }
    const formGroup = this.fb.group({
      id: [item.plot_id],
      area: [item.area, [Validators.required, Validators.max(item.field_area)]],
      estimatedYield: [item.estimated_yield],
      variety: [this.varietiesCacheService.getVariety(item.variety_id)],
      plannedHarvestDate: [item.planned_harvest_date],
      actualHarvestDate: [item.actual_harvest_date],
      plannedPlantingDate: [item.planned_planting_date],
      actualPlantingDate: [item.actual_planting_date],
      isHarvestedAndClosed: [item.is_harvested_and_closed],
      isOpen: [!item.is_closed],
      totalProduction: [
        item.actual_harvest_date ? item.total_production : undefined
      ]
    });

    row.filteredVarieties$ = formGroup.controls.variety.valueChanges.pipe(
      takeUntil(this.unsubscribeObservables$),
      startWith(''),
      debounceTime(100),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(search => {
        return this.varietiesCacheService.getVarietiesForCrop(
          item.genus_id,
          search
        );
      })
    );

    formGroup.controls.actualHarvestDate.valueChanges
      .pipe(takeUntil(this.unsubscribeObservables$))
      .subscribe(() => {
        const oldValue = formGroup.value.actualHarvestDate;
        this.dialog
          .open(SetActualYieldComponent, {
            width: '500px',
            disableClose: true
          })
          .afterClosed()
          .subscribe(result => {
            if (!result) {
              formGroup.patchValue(
                { actualHarvestDate: oldValue },
                { onlySelf: true, emitEvent: false }
              );
            } else {
              formGroup.controls.totalProduction.patchValue(result);
            }
          });
      });

    return formGroup;
  }
  onItemClicked(item: FieldInventory): void {
    if (!this.isEditMode) {
      this.itemClick.emit(item);
    }
  }
}
