import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  FieldInventory,
  FieldInventoryFilter,
  FieldInventoryRQ
} from '@farm/field-inventory/models';
import { Plot } from '@farm/field-inventory/models/plot.model';
import { mergeMap } from 'rxjs/operators';
import { FarmFilter } from '@shared/core';
import { FieldStats } from '@shared/charts/field-stats/models/field-stats.model';
import { copyIntegerArrayParams } from '@shared/core/services/http-utils.service';

export interface Page<T> {
  results: T[];
  total: number;
}

@Injectable()
export class FieldInventoryService {
  constructor(private http: HttpClient) {}

  getInventory(
    farmFilter: FarmFilter,
    fieldInventoryFilter: FieldInventoryFilter,
    pageNumber: number,
    pageSize: number
  ): Observable<Page<FieldInventory>> {
    let params = new HttpParams();
    params = this.appendFarmFilter(params, farmFilter);
    params = this.appendFieldInventoryFilter(params, fieldInventoryFilter);
    params = params
      .append('page_size', String(pageSize))
      .append('page', String(pageNumber));

    return this.http.get<Page<FieldInventory>>('api/plot/inventory/report/', {
      params
    });
  }

  getPlot(id: number): Observable<FieldInventory> {
    return this.http.get<FieldInventory>(`api/plot/inventory/report/${id}`);
  }

  getCsv(
    farmFilter: FarmFilter,
    fieldInventoryFilter: FieldInventoryFilter
  ): Observable<BlobPart> {
    let params = new HttpParams();
    params = this.appendFarmFilter(params, farmFilter);
    params = this.appendFieldInventoryFilter(params, fieldInventoryFilter);
    params = params.append('format', 'csv');
    return this.http.get('api/plot/inventory/report', {
      params,
      responseType: 'blob'
    });
  }

  getStats(
    farmFilter: FarmFilter,
    fieldInventoryFilter: FieldInventoryFilter
  ): Observable<FieldStats> {
    let params = new HttpParams();
    params = this.appendFarmFilter(params, farmFilter);
    params = this.appendFieldInventoryFilter(params, fieldInventoryFilter);
    return this.http.get<FieldStats>('api/plot/inventory/stats/', {
      params
    });
  }

  public appendFarmFilter(
    params: HttpParams,
    farmFilter: FarmFilter
  ): HttpParams {
    if (
      farmFilter.growing_season &&
      farmFilter.growing_season !== 'All Seasons'
    ) {
      params = params.append('growing_season', farmFilter.growing_season);
    }
    if (farmFilter.team_id) {
      params = params.append('team_id', farmFilter.team_id.toString());
    }
    if (farmFilter.farm_id) {
      params = params.append('farm_id', farmFilter.farm_id.toString());
    }
    if (farmFilter.field_id) {
      params = params.append('field_id', farmFilter.field_id.toString());
    }
    return params;
  }

  public appendFieldInventoryFilter(
    params: HttpParams,
    fieldInventoryFilter: FieldInventoryFilter
  ): HttpParams {
    params = copyIntegerArrayParams(
      ['genus_ids', 'variety_ids'],
      params,
      fieldInventoryFilter
    );
    if (fieldInventoryFilter.plot) {
      params = params.append('search', fieldInventoryFilter.plot);
    }
    if (fieldInventoryFilter.state) {
      params = params.append('state', fieldInventoryFilter.state.toString());
    }
    return params;
  }

  public update(item: FieldInventoryRQ): Observable<FieldInventory> {
    // post to public Plot API, then refetch the inventory for this plot
    return this.http
      .patch<Plot>(`api/plot/${item.plot_id}/`, item)
      .pipe(mergeMap(_ => this.getPlot(item.plot_id)));
  }
}
