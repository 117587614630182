import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { Article } from '../models/article.model';
import { ArticleService } from '../services/article.service';

@Component({
  selector: 'app-articles-summary',
  template: require('./articles-summary.component.html'),
  styles: [require('./articles-summary.component.scss')]
})
export class ArticlesSummaryComponent implements OnInit {
  @Output() showArticle = new EventEmitter<Article>();


  articles: Article[] = [];

  constructor(private articleService: ArticleService) { }

  ngOnInit() {
    this.articleService.query().subscribe(articles => {
      this.articles = articles.slice(0, 3);
    });
  }

  articleClicked(article: Article): void {
    this.showArticle.emit(article);
  }

}
