import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {delay, map, switchMap} from 'rxjs/operators';
import {GenericQueryService} from '@shared/core/services/generic-query-service';

/**
 * Generic named entity uniqueness validator
 * @param queryService: named entity query service
 * @param id: existing id, if any
 * @constructor
 */
export function NameUniquenessValidator(
  queryService: GenericQueryService,
  id?: number
): AsyncValidatorFn {
  return (
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return of(control.value).pipe(
      delay(300),
      switchMap(value => queryService.query(value, true)),
      map(page => {
        const found = page.results.some(
          entity =>
            entity.name.toLowerCase() === control.value.toLowerCase() &&
            entity.id !== id
        );
        if (!found) {
          return null;
        }

        return { nameUniqueness: true };
      })
    );
  };
}
