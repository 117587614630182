import {DataSource} from '@angular/cdk/table';
import {FieldInventory, FieldInventoryFilter} from '@farm/field-inventory/models';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {FieldInventoryService, Page} from '../services/field-inventory.service';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CollectionViewer} from '@angular/cdk/collections';
import {TableRow} from '@farm/field-inventory/models/table-row.model';
import {FarmFilter} from '@shared/core';

@Injectable()
export class FieldInventoryDataSource
  implements DataSource<TableRow<FieldInventory>> {
  private fieldInventorySubject = new BehaviorSubject<
    TableRow<FieldInventory>[]
  >([]);

  public total = 0;
  public results$ = this.fieldInventorySubject.asObservable();

  constructor(private fieldInventoryService: FieldInventoryService) {}
  connect(
    collectionViewer: CollectionViewer
  ): Observable<TableRow<FieldInventory>[]> {
    return this.results$;
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.fieldInventorySubject.complete();
  }

  loadItems(
    farmFilter: FarmFilter,
    fieldInventoryFilter: FieldInventoryFilter,
    pageNumber,
    pageSize
  ): void {
    this.fieldInventoryService
      .getInventory(farmFilter, fieldInventoryFilter, pageNumber, pageSize)
      .pipe(catchError(() => of({ results: [], total: 0 })))
      .subscribe((page: Page<FieldInventory>) => {
        this.total = page.total;
        const tableRows = page.results.map(item => ({ item }));
        this.fieldInventorySubject.next(tableRows);
      });
  }

  resetRows() {
    if (this.fieldInventorySubject.value) {
      this.fieldInventorySubject.value.forEach(tableRow => {
        tableRow.form = null;
      });
    }
  }
}
