import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@shared';
import {ReactiveFormsModule} from '@angular/forms';
import {FarmSidebarComponent} from './farm-sidebar/farm-sidebar.component';
import {FarmDialogComponent} from './farm-dialog/farm-dialog.component';
import {WeatherModule} from '@weather/weather.module';
import {PlotDialogComponent} from './plot-dialog/plot-dialog.component';
import {FieldDialogComponent} from './field-dialog/field-dialog.component';

@NgModule({
  declarations: [FarmDialogComponent, FarmSidebarComponent, PlotDialogComponent, FieldDialogComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, WeatherModule]
})
export class MapModule {}
