import {Injectable} from '@angular/core';
import {Field} from '@shared/core/models/field.model';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {FieldDialogComponent} from '@farm/map/field-dialog/field-dialog.component';
import {Boundary} from '@shared/core/models/boundary';
import {FieldService} from '@shared/core';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FieldDialogService {

  constructor(private dialog: MatDialog, private fieldService: FieldService) {}

  private open(data: any) : Observable<Field> {
    return this.dialog
      .open(FieldDialogComponent, {
        disableClose: true,
        width: '500px',
        data: data
      })
      .afterClosed();
  }

  public openEditDialog(id: number, boundary: Boundary) : Observable<Field> {
    return this.fieldService.get(id).pipe( switchMap( (field) => {
      field.boundary = boundary;
      return this.open(field);
    }));
  }

  public openCreateDialog(boundary: Boundary, farm_id?: number): Observable<Field> {
    return this.open({farm_id: farm_id, boundary: boundary});
  }
}
