import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {iif, ReplaySubject, Subject} from 'rxjs';
import {Team, TeamService} from '@shared/core';
import {debounceTime, filter, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Farm} from '@shared/core/models/farm.model';
import {FarmService} from '@shared/core/services/farm.service';
import {NameUniquenessValidator} from '@farm/map/common/name-uniqueness-validator';

@Component({
  selector: 'app-farm-dialog',
  template: require('./farm-dialog.component.html'),
  styles: [require('./farm-dialog.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class FarmDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isNew: boolean;

  public teamFilterControl: FormControl = new FormControl();
  public isSearchingTeam = false;
  public teams$: ReplaySubject<Team[]> = new ReplaySubject<Team[]>(1);

  protected unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: Farm,
    private dialogRef: MatDialogRef<FarmDialogComponent>,
    private farmService: FarmService,
    private snackBar: MatSnackBar,
    private teamService: TeamService
  ) {}

  ngOnInit() {
    this.isNew = !this.data.id;
    this.form = this.buildForm(this.data);
    this.wireTeamFilter();
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(farm: Farm): FormGroup {
    return this.fb.group({
      id: [farm.id],
      name: [
        farm.name,
        [Validators.required, Validators.maxLength(100)],
        NameUniquenessValidator(this.farmService, farm.id)
      ],
      team_id: [farm.team_id, Validators.required],
      notes: [farm.notes],
      location: [farm.location],
      address: this.fb.group({
        country_code: [farm.address.country_code || ''],
        address1: [farm.address.address1 || ''],
        address2: [farm.address.address2 || ''],
        address3: [farm.address.address3 || ''],
        address4: [farm.address.address4 || '', Validators.required],
        address_code: [farm.address.address_code || '']
      })
    });
  }
  wireTeamFilter(): void {
    this.teamFilterControl.valueChanges
      .pipe(
        filter(search => !!search && !!search.trim()),
        startWith(''),
        tap(() => (this.isSearchingTeam = true)),
        takeUntil(this.unsubscribe$),
        debounceTime(200),
        switchMap((search: string) => this.teamService.getTeams(search, true)),
        map(page => page.results)
      )
      .subscribe(
        teams => {
          this.isSearchingTeam = false;
          this.teams$.next(teams);
        },
        error => {
          this.isSearchingTeam = false;
          // TODO: We need to review ALL the messages. PMs want a better UX.
          this.snackBar.open(
            'Something went wrong while retrieving he teams! Please contact support team.',
            'CLOSE'
          );
        }
      );
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    const farm = this.form.getRawValue();
    iif(
      () => this.isNew,
      this.farmService.create(farm),
      this.farmService.update(farm)
    ).subscribe(
      response => {
        this.dialogRef.close(response);
      },
      err => {
        // TODO: We need to review ALL the messages. PMs want a better UX.
        this.snackBar.open(
          'Something went wrong! Please contact support team.',
          'CLOSE'
        );
      }
    );
  }
}
