import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BurndownDateDialogComponent } from '@potato/burndown-date-dialog/burndown-date-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared';
import { HarvestDetailsDialogComponent } from './harvest-details-dialog/harvest-details-dialog.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [BurndownDateDialogComponent, HarvestDetailsDialogComponent],
  imports: [
    MatMomentDateModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    FormsModule
  ],
  exports: [],
  entryComponents: [BurndownDateDialogComponent, HarvestDetailsDialogComponent]
})
export class PotatoModule {}
