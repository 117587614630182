import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PlotService} from '@shared/core/services/plot.service';

@Component({
  selector: 'app-check-delete-plot-dialog',
  template: require('./check-delete-plot-dialog.component.html'),
  styles: [require('./check-delete-plot-dialog.component.scss')]
})
export class CheckDeletePlotDialogComponent implements OnInit {

  public canDelete: boolean;

  constructor(public dialogRef: MatDialogRef<CheckDeletePlotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private plotService: PlotService) { }

  ngOnInit() {
    this.canDelete = this.data.canDelete;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  ok() {
    this.plotService.delete(this.data.id).subscribe( () => {
      this.dialogRef.close(true);
    }, ()=> {
      // fail
      this.snackBar.open("Failed to delete plot", "OK", { duration: 5000 });
      this.dialogRef.close(false);
    })
  }

}
