import { Injectable } from '@angular/core';
import { Variety } from '@farm/field-inventory/models';
import { VarietyService } from '@shared/core/services/variety.service';

@Injectable({
  providedIn: 'root'
})
export class VarietiesCacheService {
  private varietiesByCrop: Map<number, Variety[]> = new Map();
  private varietiesById: Map<number, Variety> = new Map();

  constructor(varietyService: VarietyService) {
    // just fetch all the varieties - it's now a quick call
    varietyService.query().subscribe(varieties => {
      varieties.forEach(v => {
        this.varietiesById[v.id] = v;
        if (!this.varietiesByCrop[v.crop_id]) {
          this.varietiesByCrop[v.crop_id] = [];
        }
        this.varietiesByCrop[v.crop_id].push(v);
      });
    });
  }

  /**
   * Returns the list of available varieties for the specified crop
   * @param genusId: genus ID
   * @param search:  optional search string
   */
  getVarietiesForCrop(genusId: number, search?: string): Variety[] {
    if (search) {
      search = search.toLowerCase();
    }
    return this.varietiesByCrop[genusId].filter(v => {
      return (
        v.crop_id === genusId &&
        (!search || v.name.toLowerCase().startsWith(search))
      );
    });
  }

  getVariety(id: number): string {
    return this.varietiesById[id];
  }
}
