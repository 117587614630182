import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, iif, Observable } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MAT_DATE_FORMATS
} from '@angular/material';
import {
  Genus,
  GenusService,
  VarietyService,
  Variety,
  Field,
  SoilType,
  SoilTypeService,
  Plot,
  PlotService
} from '@shared/core';
import { switchMap, takeUntil, startWith, filter } from 'rxjs/operators';
import { DatePickerFormats } from '@shared/core/constants/date-formats';

@Component({
  selector: 'app-plot-dialog',
  template: require('./plot-dialog.component.html'),
  styles: [require('./plot-dialog.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DatePickerFormats }]
})
export class PlotDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isNew: boolean;

  genuses$: Observable<Array<Genus>>;
  varieties$: Observable<Array<Variety>>;
  soilTypes$: Observable<Array<SoilType>>;

  unsubscribe$ = new Subject<void>();

  plot: Plot;
  field: Field;

  PLANTED_AREA_MIN = 0.0001;
  PLANTED_AREA_MAX = 999.9999;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { plot: Plot; field: Field },
    private dialogRef: MatDialogRef<PlotDialogComponent>,
    private plotService: PlotService,
    private snackBar: MatSnackBar,
    private genusService: GenusService,
    private varietyService: VarietyService,
    private soilTypeService: SoilTypeService
  ) {}

  ngOnInit() {
    this.isNew = !this.data.plot.id;
    this.field = this.data.field;
    this.plot = this.data.plot;
    this.form = this.buildForm(this.plot, this.field);

    this.wireEvents();
    this.genuses$ = this.genusService.query();
    this.soilTypes$ = this.soilTypeService.query();
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  wireEvents(): void {
    this.varieties$ = this.form.controls.crop_id.valueChanges.pipe(
      startWith(this.plot.crop_id),
      filter(value => !!value),
      takeUntil(this.unsubscribe$),
      switchMap(cropId => this.varietyService.query(cropId))
    );

    this.form.controls.use_field_bounds.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: boolean) => {
        if (value) {
          this.form.patchValue({
            planting_area: this.field.boundary.area,
            boundary: this.field.boundary
          });
        } else {
          this.form.patchValue({
            planting_area: this.plot.boundary.area,
            boundary: this.plot.boundary
          });
        }
      });
  }

  buildForm(plot: Plot, field: Field): FormGroup {
    return this.fb.group({
      id: [plot.id],
      name: [plot.name, [Validators.required]],
      crop_id: [plot.crop_id, [Validators.required]],
      variety_id: [plot.variety_id],
      soil_type_id: [
        plot.soil_type_id || field.soil_type_id,
        [Validators.required]
      ],
      planting_area: [
        plot.planting_area,
        [
          Validators.required,
          Validators.min(this.PLANTED_AREA_MIN),
          Validators.max(this.PLANTED_AREA_MAX)
        ]
      ],
      use_field_bounds: [
        {
          value: field.boundary.area === plot.boundary.area,
          disabled: !this.isNew
        }
      ],
      planned_planting_date: [
        plot.planned_planting_date,
        [Validators.required]
      ],
      registration_code: [plot.registration_code],
      notes: [plot.notes],
      boundary: [plot.boundary]
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    const cleanFormValue = this.getCleanFormValue();
    const cleanPlot = this.getCleanPlot();

    // Saving
    const plot = Object.assign({}, cleanPlot, cleanFormValue);
    iif(
      () => this.isNew,
      this.plotService.create(plot),
      this.plotService.update(plot)
    ).subscribe(
      response => {
        this.dialogRef.close(response);
      },
      _ => {
        // TODO: We need to review ALL the messages. PMs want a better UX.
        this.snackBar.open(
          'Something went wrong! Please contact support team.',
          'CLOSE'
        );
      }
    );
  }

  private getCleanFormValue(): any {
    const rawPlot = this.form.value;
    // Cleaning Raw Plot
    delete rawPlot.use_field_bounds;
    if (!rawPlot.notes) {
      delete rawPlot.notes;
    }
    if (!rawPlot.registration_code) {
      delete rawPlot.registration_code;
    }
    return rawPlot;
  }
  private getCleanPlot(): Plot {
    const plot = Object.assign({}, this.plot);

    // Cleaning plot
    if (plot.planned_yield === null) {
      delete plot.planned_yield;
    }
    delete plot.planting;
    delete plot.harvest;

    return plot;
  }
}
