import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GrowingSeasonService, TeamSelectionService } from '@shared/core';
import { BaseEntity } from '@shared/core/models/base-entity.model';
import { FarmSummaryService } from '@shared/core/services/farm-summary.service';
import { GenusVarietyService } from '@shared/core/services/genus-variety.service';
import { Crop } from '@shared/core/models/crop.model';
import { Variety } from '@farm/field-inventory/models';
import _ from 'underscore';
import { DashboardFilter } from '@dashboard/shared/models/dashboard-filter.model';

@Component({
  selector: 'app-dashboard-filter',
  template: require('./dashboard-filter.component.html'),
  styles: [require('./dashboard-filter.component.scss')]
})
export class DashboardFilterComponent implements OnInit, OnDestroy {
  @Output() selected = new EventEmitter<DashboardFilter>();

  private unsubscribeObservables$: Subject<void> = new Subject();
  farms: Array<BaseEntity> = [];
  crops: Array<Crop> = [];
  varieties: Array<Variety> = [];
  filterForm: FormGroup = this.fb.group({
    farm_id: [''],
    genus_id: [''],
    variety_id: ['']
  });
  // tslint:disable-next-line: variable-name
  team_id: number;
  // tslint:disable-next-line: variable-name
  growing_season: string;

  constructor(
    private selectedTeamService: TeamSelectionService,
    private growingSeasonService: GrowingSeasonService,
    private farmSummaryService: FarmSummaryService,
    private genusVarietyService: GenusVarietyService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    combineLatest(
      this.selectedTeamService.get$(),
      this.growingSeasonService.getCurrent()
    )
      .pipe(
        debounceTime(100),
        takeUntil(this.unsubscribeObservables$),
        tap(([team, season]) => {
          this.team_id = team ? team.id : null;
          this.growing_season = season.value;
        }),
        switchMap(([team, season]) => {
          return combineLatest(
            this.farmSummaryService.getFarmsWithPlots(this.team_id),
            this.genusVarietyService.getCrops({
              team_id: this.team_id,
              growing_season: season.value
            })
          );
        })
      )
      .subscribe(([farms, crops]) => {
        this.crops = crops;
        this.farms = farms;
        // we need to ensure that the first mat-option is always selected: that's why
        // we explicitly set the values to ''
        this.filterForm.reset({ farm_id: '', genus_id: '', variety_id: '' });
      });

    this.filterForm.controls.genus_id.valueChanges.subscribe(val => {
      this.filterForm.patchValue({ variety_id: '' });
      if (val) {
        // initialise varieties for the crop
        this.varieties = _.findWhere(this.crops, {
          id: this.filterForm.controls.genus_id.value
        }).varieties;
      }
    });

    this.filterForm.controls.farm_id.valueChanges.subscribe(val => {
      this.filterForm.patchValue({ genus_id: '', variety_id: '' });
    });

    // changing the genus or farm will cause the variety to be reset
    // this means that we really only need to listen for changes to variety_id
    this.filterForm.controls.variety_id.valueChanges
      .pipe(debounceTime(100))
      .subscribe(val => {
        const output = { ...this.filterForm.value };
        output.team_id = this.team_id;
        output.growing_season = this.growing_season;
        this.selected.next(output);
      });
  }

  isEmpty(): boolean {
    return this.farms.length === 0;
  }

  ngOnDestroy(): void {
    this.unsubscribeObservables$.next();
    this.unsubscribeObservables$.unsubscribe();
  }
}
