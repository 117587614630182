import { Component, OnInit } from '@angular/core';
import { ProfileService } from '@shared/core';
import { WelcomeProfile } from '../shared/models/welcome-profile.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-welcome',
  template: require('./welcome.component.html'),
  styles: [require('./welcome.component.scss')]
})
export class WelcomeComponent implements OnInit {
  profile$: Observable<WelcomeProfile>;

  constructor(private profileService: ProfileService) { }

  ngOnInit() {
    this.profile$ = this.profileService.get() as Observable<WelcomeProfile>;
  }
}
