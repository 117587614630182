import { Component } from '@angular/core';
import { DashboardFilter } from '@dashboard/shared/models/dashboard-filter.model';
import { FieldStatsService } from '@shared/charts/field-stats/services/field-stats.service';
import { FieldStats } from '@shared/charts/field-stats/models/field-stats.model';

@Component({
  selector: 'app-dashboard',
  template: require('./dashboard.component.html'),
  styles: [require('./dashboard.component.scss')]
})
export class DashboardComponent {
  stats: FieldStats;

  constructor(private fieldStatsService: FieldStatsService) {}

  filterChanged(filter: DashboardFilter) {
    this.fieldStatsService.get(filter).subscribe(stats => {
      this.stats = stats;
    });
  }
}
