import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Crop, FieldInventoryFilter, PlotActiveState} from '../models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, map, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-field-inventory-filter',
    template: require('./field-inventory-filter.component.html'),
    styles: [require('./field-inventory-filter.component.scss')]
})
export class FieldInventoryFilterComponent
    implements OnInit, OnDestroy, OnChanges {
    unsubscribeObservables$: Subject<void> = new Subject();

    @Input() crops: Crop[];
    @Input() disabled: boolean;
    @Output() filterChange: EventEmitter<FieldInventoryFilter> = new EventEmitter<FieldInventoryFilter>();
    filterForm: FormGroup;
    states: PlotActiveState[];
    selectedState: PlotActiveState;


    crops$: Observable<Crop[]>;

    constructor(private fb: FormBuilder) {
        this.states = [
            {name: 'All', value: 'all'},
            {name: 'Active', value: 'open'},
            {name: 'Closed', value: 'closed'}
            ];
        this.selectedState = this.states[1];
        this.filterForm = this.fb.group({
            state: [this.selectedState],
            plot: [''],
            crops: [null],
            varieties: [null]
        });
    }

    ngOnInit() {
        this.filterForm.controls.state.setValue(this.selectedState, {});
        this.crops$ = this.filterForm.controls.crops.valueChanges.pipe(
            map(
                (crops: Crop[]): Crop[] => {
                    if (!crops) {
                        return [];
                    }

                    return [...crops];
                }
            )
        );
        this.onFormChanges();
    }

    ngOnDestroy() {
        this.unsubscribeObservables$.next();
        this.unsubscribeObservables$.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.crops) {
            this.filterForm.reset();
            this.filterForm.controls.state.setValue(this.selectedState, {});
        }
    }

    onFormChanges() {
        combineLatest(
            this.filterForm.controls.state.valueChanges,
            this.filterForm.controls.plot.valueChanges,
            this.filterForm.controls.crops.valueChanges,
            this.filterForm.controls.varieties.valueChanges
        )
            .pipe(
                debounceTime(300),
                map(
                    ([state, plot, crops, varieties]): FieldInventoryFilter => ({
                        state: state ? state.value : null,
                        plot,
                        genus_ids: crops ? crops.map(c => c.id) : [],
                        variety_ids: varieties ? varieties.map(s => s.id) : []
                    })
                ),
                takeUntil(this.unsubscribeObservables$)
            )
            .subscribe(
                (filter: FieldInventoryFilter): void => {
                    if (!this.disabled) {
                        this.filterChange.emit(filter);
                    }
                }
            );
    }
}
