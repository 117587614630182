import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FarmGeojson} from '../common/models';
import {BehaviorSubject, Observable} from 'rxjs';
import {FarmDialogService} from '../common/services/farm-dialog.service';
import {filter, switchMap, tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material';
import {User, UserService} from '@shared/core';
import {DeleteFarmService} from '@farm/delete-farm.service';
import {Farm} from '@shared/core/models/farm.model';
import {FarmService} from '@shared/core/services/farm.service';

@Component({
  selector: 'app-farm-sidebar',
  template: require('./farm-sidebar.component.html'),
  styles: [require('./farm-sidebar.component.scss')]
})
export class FarmSidebarComponent implements OnInit, OnChanges {
  @Input()
  farmId: number;

  @Output()
  itemChanged: EventEmitter<FarmGeojson> = new EventEmitter<FarmGeojson>();

  @Output()
  notificationClick: EventEmitter<Farm> = new EventEmitter<Farm>();

  @Output()
  farmDeleted: EventEmitter<Farm> = new EventEmitter<Farm>();

  farm$: Observable<Farm>;
  hasWritePermission: boolean;
  farmIdSubject$ = new BehaviorSubject<number>(null);

  updatedByUser$: Observable<User>;

  constructor(
    private farmService: FarmService,
    private snackBar: MatSnackBar,
    private farmDialogService: FarmDialogService,
    private userService: UserService,
    private deleteFarmService: DeleteFarmService
  ) {
    this.farm$ = this.farmIdSubject$.asObservable().pipe(
      switchMap(id => this.farmService.get(id)),
      tap(farm => {
        this.updatedByUser$ = this.userService.get(farm.updated_by_id);
      })
    );
  }

  ngOnInit() {}

  ngOnChanges(): void {
    if (this.farmId) {
      this.farmIdSubject$.next(this.farmId);
      this.farmService
        .hasWritePermission(this.farmId)
        .subscribe(
          hasWritePermission => (this.hasWritePermission = hasWritePermission)
        );
    }
  }

  edit(farm: Farm): void {
    this.farmDialogService
      .open(farm)
      .pipe(filter(response => !!response))
      .subscribe(response => {
        this.snackBar.open('Created successfully', 'CLOSE');
        this.farmIdSubject$.next(this.farmId);
        this.itemChanged.emit(response);
      });
  }
  notificationSubscription(farm: Farm): void {
    this.notificationClick.emit(farm);
  }
  delete(farm: Farm): void {
    this.deleteFarmService.checkDeleteFarm(farm.id).subscribe( (deleted) => {
      if (deleted) {
        this.farmDeleted.emit(farm);
      }
    });
  }
}
