import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {DashboardComponent} from './dashboard.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {DashboardFilterComponent} from './dashboard-filter/dashboard-filter.component';
import {MaterialModule} from '@shared/material/material.module';
import {CoreModule} from '@shared/core';
import {FieldStatsModule} from '@shared/charts';
import {ArticlesSummaryModule} from './articles-summary/articles-summary.module';
import {ArticlesSummaryComponent} from './articles-summary/articles-summary/articles-summary.component';
import {NavbarModule} from '@shared/components/navbar/navbar.module';

@NgModule({
    declarations: [DashboardComponent, WelcomeComponent, DashboardFilterComponent],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        CoreModule.forRoot(),
        FieldStatsModule,
        ArticlesSummaryModule,
        NavbarModule
    ],
    exports: [DashboardComponent],
    entryComponents: [DashboardComponent, ArticlesSummaryComponent]
})
export class DashboardModule {
}
