import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-set-actual-yield',
  template: require('./set-actual-yield.component.html'),
  styles: [require('./set-actual-yield.component.scss')]
})
export class SetActualYieldComponent implements OnInit {
  form = this.fb.group({
    actualYield: [null, Validators.required]
  });


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SetActualYieldComponent>) { }

  ngOnInit() {
  }
  save() {
    if (!this.form.valid) {
      return;
    }

    const actualYield = this.form.value.actualYield;
    this.dialogRef.close(actualYield);
  }

}
