import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material';
import { EstimatedActualCellComponent } from './estimated-actual-cell/estimated-actual-cell.component';

@NgModule({
  declarations: [EstimatedActualCellComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    EstimatedActualCellComponent
  ]
})
export class EstimatedActualCellModule { }


