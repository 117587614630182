import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Article, TextArticle } from '../models/article.model';

@Injectable()
export class ArticleService {

  constructor(private http: HttpClient) { }

  query(): Observable<Article[]> {
    return this.http.get<any>('/api/v2.0/articles/data').pipe(filter((rs: any) => rs.data !== null), map((rs: any): Article[] => {
      return rs.data
        // TODO: for now we are only showing TextArticles
        .filter((item: any) => !item.video_content)
        .map((item: any) => {
        return {
          slug: item.slug,
          title: item.title,
          publish_date_readable: item.publish_date_readable,
          featured_article: item.featured_article,
          featured_image: item.featured_image && item.featured_image.length ?
            item.featured_image[0].image_file : require('../../../../../../../img/farm_plus.jpg'),
        } as TextArticle;
      });
    }));
  }
}
