import { NgModule } from '@angular/core';
import {CommonModule, Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

import {GenericBannerComponent} from './generic-banner.component';
import {BannerService} from './shared/services/banner.service';

@NgModule({
  declarations: [GenericBannerComponent],
  imports: [
    CommonModule
  ],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    BannerService
  ],
  entryComponents: [GenericBannerComponent]
})
export class GenericBannerModule { }
