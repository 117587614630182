import { Component, Inject, OnInit } from '@angular/core';
import { Genus, GenusService } from '@shared/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-variety-dialog',
  template: require('./add-variety-dialog.component.html'),
  styles: [require('./add-variety-dialog.component.scss')]
})
export class AddVarietyDialogComponent implements OnInit {
  form: FormGroup;
  genera$: Observable<Genus[]>;

  constructor(
    private genusService: GenusService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddVarietyDialogComponent>
  ) {}

  ngOnInit() {
    this.genera$ = this.genusService.query();
    this.form = this.fb.group({
      name: [null, Validators.required],
      crop_id: [null, Validators.required]
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
