import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BurndownDateDialogComponent } from '@potato/burndown-date-dialog/burndown-date-dialog.component';
import { OperationService } from '@shared/core/services/operation.service';
import { PesticideOperation } from '@shared/core/models/pesticide-operation';
import { filter, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BurndownDateService {
  constructor(
    private dialog: MatDialog,
    private operationService: OperationService
  ) {}

  public open(
    // tslint:disable-next-line: variable-name
    plot_id: number,
    // tslint:disable-next-line: variable-name
    start_date?: Date,
    // tslint:disable-next-line: variable-name
    burndown_product_id?: number
  ): Observable<PesticideOperation> {
    return this.dialog
      .open(BurndownDateDialogComponent, {
        width: '500px',
        data: {
          start_date,
          burndown_product_id
        }
      })
      .afterClosed()
      .pipe(
        filter(result => result),
        switchMap(result => {
          if (start_date || burndown_product_id) {
            // get the most recent burndown operation and then put it back
            return this.operationService.getBurndown(plot_id).pipe(
              switchMap(op => {
                op.start_date = result.start_date;
                if (result.product) {
                  op.products = [{ product_id: result.product.id }];
                } else {
                  op.products = [];
                }
                return this.operationService.updatePesticide(op);
              })
            );
          } else {
            // otherwise we want to create a new burndown pesticide operation
            const op: PesticideOperation = {
              type: 'pesticide',
              plot_id,
              start_date: result.start_date,
              is_applied: true,
              is_burndown: true,
              products: []
            };
            if (result.product) {
              op.products.push({ product_id: result.product.id });
            }
            return this.operationService.savePesticide(op);
          }
        })
      );
  }
}
