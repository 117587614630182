import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FieldStatsModule} from '@shared/charts/field-stats';
import {FieldInventoryComponent, FieldInventoryModule} from './field-inventory';
import {FarmDialogComponent} from './map/farm-dialog/farm-dialog.component';
import {FarmSidebarComponent} from './map/farm-sidebar/farm-sidebar.component';
import {MapModule} from './map';
import {PlotDialogComponent} from './map/plot-dialog/plot-dialog.component';
import {AddVarietyDialogComponent} from '@farm/variety/add-variety-dialog/add-variety-dialog.component';
import {MaterialModule} from '@shared';
import {ReactiveFormsModule} from '@angular/forms';
import {DeleteFarmDialogComponent} from './delete-farm-dialog/delete-farm-dialog.component';
import {DeleteFieldDialogComponent} from './delete-field-dialog/delete-field-dialog.component';
import {CheckDeletePlotDialogComponent} from './check-delete-plot-dialog/check-delete-plot-dialog.component';
import {FieldDialogComponent} from '@farm/map/field-dialog/field-dialog.component';

@NgModule({
  declarations: [AddVarietyDialogComponent, DeleteFarmDialogComponent, DeleteFarmDialogComponent, DeleteFieldDialogComponent, CheckDeletePlotDialogComponent],
  imports: [
    CommonModule,
    FieldStatsModule,
    FieldInventoryModule,
    MapModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [],
  entryComponents: [
    FieldInventoryComponent,
    FarmDialogComponent,
    FieldDialogComponent,
    FarmSidebarComponent,
    PlotDialogComponent,
    AddVarietyDialogComponent,
    FieldInventoryComponent,
    DeleteFarmDialogComponent,
    DeleteFieldDialogComponent,
    CheckDeletePlotDialogComponent
  ]
})
export class FarmModule {}
